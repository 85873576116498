import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { IRun } from '@app/core/model/runs/run';
import { Read } from '@bssh/ng-sdk';
import { each } from 'lodash';

const NA: string = '--';
const DELIMITER: string = ' | ';
const STATUS: string[] = ['Running', 'Paused', 'Uploading'];

@Pipe({
    name: 'cycle-count',
})
@Injectable({
    providedIn: 'root'
})
export class CycleCountPipe implements PipeTransform {
    transform(run: IRun, reads?: Read[]): string {
        const status = run ? run.Status : null;
        const stats = run ? run.SequencingStats : null;

        if (!stats) {
            return NA;
        }

        if (STATUS.indexOf(status) > -1 || !reads) {
            return stats.NumCyclesRead1 + DELIMITER + stats.NumCyclesRead2;
        }

        const statsArray = [];
        each(reads, (read: Read) => {
            statsArray.push(read.TotalCycles);
        });

        return statsArray.length > 0 ? statsArray.join(DELIMITER) : NA;
    }
}