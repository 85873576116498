<ngx-smart-modal
    #appEulaModal
    class="app-eula-modal"
    [identifier]="modalType"
    [closable]="true"
    [title]="modalTitle"
    [showCloseButton]="true"
    [showConfirmButton]="true"
    [closeButtonText]="modalCloseButtonText"
    [confirmButtonText]="modalConfirmButtonText">
  <div [innerHTML]="modalContent"></div>
</ngx-smart-modal>
