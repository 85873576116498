import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { IAppLauncherModalInput } from '../model/action-modal';
import { ResourceType } from '@app/core/model/resource-type';
import environment from '@environments/environment';

@Component({
  selector: 'app-app-launcher-modal',
  templateUrl: './app-launcher-modal.component.html',
  styleUrls: ['./app-launcher-modal.component.scss']
})
export class AppLauncherModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {

  modalType = 'InitiateAppLauncherModal';
  ngxSmartModalTitle: string = '';

  resourceId: string;
  resourceType: ResourceType;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.data.subscribe((input: IAppLauncherModalInput) => {
      this.resourceId = input.Id;
      this.resourceType = input.resourceType;

      this.error.next(input.errorMsg);
    });
  }

  public createUrl = (appData: any): string => {
    return `/apps/${appData.Id}/start?redirectToPreferredVersion=true&project-id=${this.resourceId}`;
  }

  constructError(error: any): string | string[] {
    return '';
  }

}
