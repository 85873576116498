import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IAppEulaModalInput, IAppEulaModalOutput } from '../model/action-modal';

@Component({
  selector: 'app-eula-modal',
  templateUrl: './app-eula-modal.component.html',
  styleUrls: ['./app-eula-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppEulaModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {

  modalType = 'AppEulaModal';
  modalTitle = 'GENERIC APP';
  modalContent = 'GENERIC CONTENT';
  modalCloseButtonText = 'CANCEL';
  modalConfirmButtonText = 'Accept';
  isAccepted = false;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    // Assign modal text content according to input
    this.subs.sink = this.data.subscribe((modalInput: IAppEulaModalInput) => {
      if (modalInput && modalInput.content && modalInput.title && modalInput.version) {
        this.modalTitle = `${modalInput.title} ${modalInput.version} Terms of Use`;
        this.modalContent = modalInput.content;
      }
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * To be called whenever the alert modal is closed.
   * Will emit the isProceed outcome to the consumer.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  close(modal: NgxSmartModalComponent) {
    const modalOutput: IAppEulaModalOutput = { isAccepted: this.isAccepted };
    this.confirm.emit(modalOutput);
    this.confirm.complete();
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * Sets isProceed to true to be emitted on close.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this.isAccepted = true;
    modal.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
