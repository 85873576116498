<div #InitiateAppLauncherDiv>
    <ngx-smart-modal #InitiateAppLauncherModal
                     [identifier]="modalType"
                     [closable]="true"
                     customClass="modal-dialog-wide"
                     showCloseButton
                     showConfirmButton>

        <app-bs-app-launcher 
            [createUrl]="createUrl" 
            [displayLaunch]="true"
            [displayExpandedContent]="false"
        >
        </app-bs-app-launcher>


    </ngx-smart-modal>
</div>