<div class="archive-or-restore-data">
    <div class="archive-or-restore-data__wrapper">
        <div class="archive-or-restore-data__wrapper--inner">
            <div class="archive-or-restore-data__container">
                <div class="archive-or-restore-data__content">
                    <div>
                        <div class="archive-or-restore-data__result">
                            <h5 class="archive-or-restore-data__result__heading">
                                <span>{{ resourceIdentifierTitle }}</span>
                                <span>{{ resourceSizeTitle }}</span>
                            </h5>
                            <ul class="archive-or-restore-data__result__list" *ngIf="resourceList?.length > 0">
                                <li class="archive-or-restore-data__result__item" *ngFor="let item of resourceList">
                                    <svg class="archive-or-restore-data__result__icon" *ngIf="item.hasIssues === true"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M10 17.5c-4.1 0-7.5-3.4-7.5-7.5S5.9 2.5 10 2.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5zm0-14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5zm0 9c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm.5-2V6c0-.3-.2-.5-.5-.5s-.5.2-.5.5v4.5c0 .3.2.5.5.5s.5-.2.5-.5z"
                                            fill="#656066" />
                                    </svg>
                                    <span class="archive-or-restore-data__result__name">
                                        {{item.Name}}
                                    </span>
                                    <span class="archive-or-restore-data__result__value">
                                        {{item.TotalSize | bytes:2}}
                                    </span>
                                </li>
                                <li class="archive-or-restore-data__result__item" *ngIf="resourceList?.length > 1">
                                    <span class="archive-or-restore-data__result__name">
                                        <b>{{resourceList.length}} {{resourceType.toString()}}s</b>
                                    </span>
                                    <span class="archive-or-restore-data__result__value"><b>{{totalSizeOfResources}}</b></span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <lib-error-alert-message 
                                [alertMsg]="alertMsg"
                                [errorMsg]="errorMsg">
                            </lib-error-alert-message>
                        </div>
                    </div>
                    <div class="archive-or-restore-data__content-archive-or-restore" *ngIf="actionMsg?.length > 0">
                        <p class="archive-or-restore-data__content-archive-or-restore__message" [innerHtml]="actionMsg" *ngIf="actionMsg?.length > 0" >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
