import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import '@angular/compiler';
// import { AgGridModule } from 'ag-grid-angular';
import { AppInitializationModule } from './app-initialization.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiModule, HttpRequestInterceptorProvider } from '@bssh/ng-sdk';
import environment from '@environments/environment';
// example import for comp-lib
import {
  ButtonModule, UniversalNavbarModule, NgxSmartModalModule, ToastrModule, NotificationCardModule, SelectModule,
  BannerNotificationsModule, BannerNotificationsContainerModule, ProgressModule, ProgressIndicatorsModule
} from '@bssh/comp-lib';
import { BsUnivNavBarComponent } from './comp-lib-wrappers/bs-univ-nav-bar/bs-univ-nav-bar.component';
import { PageNotFoundComponent } from './settings/page-not-found/page-not-found.component';
// tslint:disable-next-line: max-line-length
import { SubscriptionErrorModalBodyComponent } from './comp-lib-wrappers/bs-univ-nav-bar/subscription-error-modal-body/subscription-error-modal-body.component';
import { SharedModule } from './shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { CookieService } from 'ngx-cookie-service';
import { httpInterceptorProviders } from './core/http-interceptors';
import { AuthProtectedDirective } from './core/directives/auth-protected.directive';
import { SearchPathResolverService } from './core/services/resolver/search-path-resolver.service';
import { RedirectPathResolverService } from './core/services/resolver/redirect-path-resolver.service';

// IPS
import { ipsApiUrl, InstrumentClient } from '@iap/ips/dist/ips';
import { PLATFORM_API_BASE_URL, PlatformClient } from './core/services/platform-service/platform-client';
import { GssApiModule } from '@stratus/gss-ng-sdk';
import { GmsApiModule } from '@stratus/gms-v2-ng-sdk';
import { StringUtilities } from './core/utilities/string-utilities';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BsGlobalBannersComponent } from './comp-lib-wrappers/bs-global-banners/bs-global-banners.component';
import { CommonModule } from '@angular/common';
import { GdsApiModule } from '@stratus/gds-ng-sdk';

import { CustomNGXLoggerService, NGXMapperService, NGXLoggerHttpService, NGXLogger, LoggerModule } from 'ngx-logger';
import { Logger } from 'ag-grid-community';
import { BetaPreviewService } from './core/services/beta-preview.service';
import { BetaPreviewResolverService } from './core/services/resolver/beta-preview-resolver.service';
import { ReloadPageService } from './core/services/reload-page.service';

// Radiance web components
import "@radiance-design-software/web-components/components/web-spinner";
import { RadSelectDirective } from './core/directives/rad-select.directive';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    BsUnivNavBarComponent,
    SubscriptionErrorModalBodyComponent,
    AuthProtectedDirective
  ],
  imports: [
    // It is required to include CommonModule.
    // without this import "The pipe 'async' could not be found" error will come in prod mode when Ivy is enabled
    // Ref: https://stackoverflow.com/questions/39625321/the-pipe-async-could-not-be-found
    CommonModule,
    BrowserModule,
    AppInitializationModule,
    AppRoutingModule,
    ApiModule.forRoot({
      rootUrl: environment.apiEndpoint,
    }),
    GssApiModule.forRoot({
      rootUrl: StringUtilities.trimTrailingSlash(environment.iapApiUrl),
    }),
    GmsApiModule.forRoot({
      rootUrl: StringUtilities.trimTrailingSlash(environment.iapApiUrl),
    }),
    GdsApiModule.forRoot({
      rootUrl: StringUtilities.trimTrailingSlash(environment.iapApiUrl),
    }),
    SharedModule,
    HttpClientModule,
    // example import for comp-lib
    ButtonModule,
    ProgressModule,
    NotificationCardModule,
    UniversalNavbarModule,
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot(),
    MomentModule,
    SelectModule,
    NgIdleKeepaliveModule.forRoot(),
    ProgressIndicatorsModule,
    LoggerModule
  ],
  providers: [
    httpInterceptorProviders,
    CookieService,
    SearchPathResolverService,
    RedirectPathResolverService,
    BetaPreviewResolverService,
    BetaPreviewService,
    ReloadPageService,
    // provide ipsApiUrl injection value for ips client.
    InstrumentClient,
    { provide: ipsApiUrl, useValue: StringUtilities.trimTrailingSlash(environment.ipsApiUrl) },
    PlatformClient,
    { provide: PLATFORM_API_BASE_URL, useValue: environment.platformUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
